.button_wrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.component_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
