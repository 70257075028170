h1 {
    color: red;
}

footer {
    background: #949087;
    text-align: center;
    padding: 3rem;
    margin-top: 80%;
}

footer ul {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

footer li {
    margin: 0 12px;
    list-style: none;
}

footer a {
    color: #fff;
}

footer a:hover {
    color: #c7c3ba;
}

footer small {
    color: #c7c3ba;
    font-size: .875rem;
}
